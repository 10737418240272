<template>
    <v-dialog
        :width="width"
        persistent
        color="primaryBackgroundColor"
        transition="scroll-y-reverse-transition"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="isOpenLocal"
    >
        <v-card
            id="form-dialog"
            style="background-color: #f1eeee"
            class="px-0 pt-3 pb-7"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon
                        class="dialog-close close-icon"
                        v-on="on"
                        @click="isOpenLocal = false"
                        >mdi-close</v-icon
                    >
                </template>
                <span>Close</span>
            </v-tooltip>
            <v-card-title
                class="align-center justify-start"
                style="background-color: #f1eeee"
                v-if="title"
            >
                <span class="secondary--text font-weight-bold">{{
                    title
                }}</span>
            </v-card-title>

            <slot></slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "base-dialog",

    props: {
        isOpen: Boolean,
        title: String,
        width: [Number, String, null],
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
        };
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },
        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}
</style>
